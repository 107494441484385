@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap');
*{
  padding: 0;
  margin: 0;
}


body {
 /* background-color: #e6efff; */
 font-family: 'Roboto', sans-serif !important;
}
p {
  font-size: 15px;
}
a{
  text-decoration: none;
}
.jc-between{
  justify-content: space-between;
}
.jc-around{
  justify-content: space-around;
}
li{
  list-style-type: none;
}
ul{
  padding-left: 0;
}
.fw-400{
  font-weight: 400;
}
.fw-500{
  font-weight: 500;
}
.fw-600{
  font-weight: 600;
}
.fw-700{
  font-weight: 700;
}
.f-14{
  font-size: 14px;
}
.f-18{
  font-size: 18px;
}
.w-full{
  width: 95%;
}
.w-35{
  width: 35%;
}
.h-44 input{
  height: 44px;
}
.br-10{
  border-radius: 10px;
}
.br-15{
  border-radius: 15px;
}
.bluetxt{
  color: var(--bluetxt);
}
.txtdarkblue{
  color:#092e87
}
.bgblue {
  background: #3191db;
}
.graytxt{
  color: var(--graytxt);
}
.aqua{
  color: var(--aqua)
}
.upper{
  text-transform: uppercase;
}
.transbtn {
  background: transparent;
  border: none;
}
.nounder, .nounder button{
  text-decoration: none;
}
.showin991{
  display: none;
}
#dashboard button.btn.btn-theme {
 
  background: #11B3BF !important;
  color: #fff !important;
  border-radius: 5px;
  padding: 3px 25px !important;
  border: 1px solid #11B3BF;
  font-size: 12px;
  font-weight: 400;

}
#dashboard button.btn.btn-theme i{color: #fff !important;}
#dashboard button.btn.btn-theme:hover i{color: #11B3BF !important;}
#dashboard button.btn.btn-theme:hover {
  background: #fff !important;
  color: #11B3BF !important;
}
.noshadow input:focus,
.noshadow select:focus,.signin input:focus,.signup input:focus{
  box-shadow: none;
}






/* header */
.search span {
  background: white;
  border-left: 0;
  border-radius: 30px;
}
.search input {
  border-radius: 30px;
  border-right: 0;
}
.search input:focus {
  box-shadow: none;
}
.profimg img{
  width: 40px;
}
#header {
  background: #193d4d;
  color: #fff;
  position: fixed;
  width: 100%;
  z-index: 9;
}
#header > .d-flex {
  height: 72px;
}
div#header > .d-flex:after {
  content: "";
  background: #031d7a;
  position: absolute;
  height: 72px;
  width: 25%;
  z-index: -1;
  /* transition: 0.5s; */
}
div#header .hamexp:after {
  max-width: 220px;
}
div#header .hamcomp:after {
  max-width: 50px;
}



/* sign modadal */
.editimg img {
  width: 100%;

  border-radius: 50%;
}
.editimg {
  position: relative;
  height: 100px;
  aspect-ratio: 1/1;
}
.editimg span {
  position: absolute;
  color: white;
  background: #f40b5e;
  padding: 6px;
  font-size: 13px;
  border-radius: 50%;
  right: 0;
  bottom: 0;
}
.close-btn {
  border: 0;
  background: var(--themeclr);
  color: #fff;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  box-shadow: 0 0 9px 1px #ffffff;
  margin-left: auto;
  margin-right: 0;
  position: absolute;
  font-size: 10px;
  right: 10px;
  top: 10px;
}
.signup{
  border-radius: 0 15px 15px 0;
}
.signin input {
  background: #0e2048 !important;
  border-color: #0e2048 !important;
  color: #afafaf !important;
  font-size: 14px;
}
.signin input::placeholder{
  color: #afafaf !important;
}
.signin input,.signup input{
  border-radius: 10px;
}
button.inputbtn {
  background: #dce3f3;
  border-radius: 30px;
  border-left: 0;
  color: gray;
  font-size: 13px;
  padding-top: 9px;
  border-color: #ced4da;
}
.doclists button {
  border: transparent;
  color: #fff;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  font-size: 12px;
}
.doclists .progress {
  height: 5px;
}
.doclists li:hover {
  box-shadow: -1px 3px 9px 1px #7eb8e4;
  padding: 10px;
  border-radius: 10px;
}
.upimg {
  background: #f3f6fd;
  padding: 10px;
  text-align: center;
}
.upimg p button {
  display: inline-block;
  width: 94px;
  height: 23px;
  overflow: hidden;
  border: none;
  box-shadow: none;
  background: transparent;
  color: #5ba5e0;
  font-weight: 700;
}
.paystatus .fa {
  font-size: 55px;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  padding: 14px;
  border: 2px solid white;
  margin-top: 28px;
  margin-bottom: 20px;
}
.profileimg {
  border-radius: 15px 15px 0 0;
}
.balance p span {
  font-weight: 700;
  font-size: 21px;
}
.profileimg img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin-top: 14px;
  margin-bottom: 5px;
}
.profileimg h2{
  margin-top: 20px;
  font-weight: 600;
}
.selectdoc select {
  background-color: #3191db !important;
  color: #fff;
}
.selectdoc select option {
  background: #fff;
  color: #000;
}
.selectbtn {
  display: inline-block;
  position: relative;
  width: 97px;
  overflow: hidden;
  height: 17px;
  
}
.selectbtn input {
  position: absolute;
  top: 0;
  opacity: 0;
}
.upimg .fa {
  font-size: 89px;
  color: #d0e3f7;
}
.dashedbord{
  border: 1px dashed #cfdbf3;
  border-radius: 10px;
}
.doclists li {
  padding: 10px;
  margin-bottom: 15px;
}
.inputbtn:hover,.inputbtn:focus{
  background:#dce3f3;
  border-color: #ced4da;
  box-shadow: none;
}
.editprofmodal input {
  border-right: 0;
}
.graybox{
  background: #f3f6fd;
}
.signin {
  color: #fff;
}
.thememodal .modal-content{
  border-radius: 15px;
}
/* cont */
#homeheader button.navbar-toggler:focus {
  box-shadow: none;
  border: none;
}
#cont{
  background-color: #e6efff;
  width: calc(100% - 220px);
  margin-left: auto;
  min-height: 100vh;
  margin-top: 72px;
  overflow: hidden;
  /* transition: 0.5s; */
}
#cont.expand {
  width: calc(100% - 50px);
}
.whitebox {
  background: white;
  border-radius: 15px;
  padding: 20px 30px;
  margin-top: 15px;
}
.boxhead{
  font-size: 15px;
}
.reffincome img {
  width: auto;
  height: 36px;
}
.reffincome h5 {
  font-weight: 700;
  margin-bottom: 0;
}
.reffid{
  display: flex;
  align-items: center;
  height: 100%;
}
.reffid span:first-child {
  flex-shrink: 0;
  font-size: 15px;
  font-weight: 600;
}
.reffid span:last-child {
  width: 100%;
  background: #dbe4f2;
  color: #0063fc;
  display: inline-block;
  border-radius: 15px;
  padding: 5px 11px;
  margin-left: 10px;
  font-size: 14px;
}
.badge.badge-theme {
  background: var(--themeclr);
  border-radius: 10px;
  font-size: 12px;
}
.security li{
  display: flex;
}
.security li h5{
  color: var(--graytxt);
  font-size: 15px;
}
.security li img {
  height: 28px;
  margin-right: 10px;
}
.coinbal img {
  height: 45px;
}
.d-none-767{
  display: block;
}
.d-block-767 {
  display: none;
}
.mobilelogo {
  height: 39px;
}



.flex-1 > * {
  flex: 1;
}
.small-input input, .small-input select {
  height: 30px;
  border-radius: 30px;
  font-size: 14px;
  background-color: #dce3f3;
}


.nounder button{
  color: white;
}


/* Home */
.transbtn.profimg:hover,.transbtn.profimg:focus {
  background-color: transparent !important;
  box-shadow: none;
}
#homeheader .navbar-toggler-icon {
  filter: invert(1);
}
.btn.btn-bord:hover,.btn.btn-bord:focus {
  color: #fff !important;
}
.btn.btn-bord.nav-link {
  background: #11B3BF;
    color: #fff;
    border-radius: 5px;
    padding: 6px 13px !important;
    border: 1px solid #11B3BF;
    font-size: 12px;
    font-weight: 400;
}
.themedrop.btn-bord
{
  display: inline-flex;
    align-items: center;
}
#homeheader {
  background: transparent;
  height: 85px;
}
#homeheader a {
  color: #fff;
  display: flex;
  font-weight: 400;
}
#homeheader .navbar-nav a img {
  width: 15px;
}
#homeheader .dropdown a:after {
  display: none;
}

.supportedcurrecy ul li {
  text-align: center;
}
.supportedcurrecy ul li img {
  width: 55px;
  height: 55px;
}
.supportedcurrecy ul li p {
  color: var(--themeclr);
  font-weight: 700;
  margin-top: 5px;
}
.supportedcurrecy ul {
  display: flex;
  justify-content: space-evenly;
  max-width: 833px;
  margin: auto;
}
.supportedcurrecy ul li {
  text-align: center;
}
.banner {
  background-image: url("./Assets/Images/banner_bg.png");

  color: white;
  flex-direction: column;
    background-size: cover;
    background-color: #193d4e;
    background-position: right 0px top 0px;
    background-repeat: no-repeat;
}
.banner h1 {
  font-size: 68px;
}
.banner h1 span {
  font-weight: 700;
}
.homebg{
  background-color: #ffffff;
}
.tmcfeature .whitebox{
  height: 100%;
}
.tmcfeature img {
  max-width: 150px;
}


/* page */
#page{
  display: flex;
}


@media(max-width:1199px){
  .signin.w-35 {
    width: 47%;
  }
}
@media(max-width:991px){
  .themedrop.btn-bord
  {
    flex-direction: column;
    max-width: 165px !important;
  }
  #homeheader .dropdown-menu 
  {
    position: absolute;
  }
  .mobiletog {display: block !important;}
  .showin991{
    display: block;
  }
  .editprofile  {
    width: 100%;
    border-radius: 0 0 12px 12px;
    height: 50px;
    padding-bottom: 20px;
  }
  .editprofile:focus{
    box-shadow: none;
  }
  .blue.editprofile {
    background: #3191db;
    color: #fff;
  }
  .white.editprofile{
    background: #fff;
    color: #3191db;
  }

  .editprof ,.signup{
    border-radius: 15px 15px 0 0;
  }
  .signin.w-35 {
    width: 100%;
  }
  .editprofmodal .w-50 {
    width: 100% !important;
  }
  #homeheader div#basic-navbar-nav {
    background: #011f2d;
    padding: 20px;
    /* min-height: calc(100vh);
    position: fixed;
    width: 100%;
    right: 0px;
    top: 0px;
    z-index: 9; */
    
  }
  .banner {
    background-position: right;
  }
  .banner h1 {
    font-size: 45px;
  }

  div#header .hamexp:after,div#header .hamcomp:after {
    /* max-width: 175px; */
    display: none;
  }
  .logo {
    width: 124px;
    height: 22px;
    margin-left: 3px;
  }
  button.hamburger {
    width: 72px;
  }
  button.hamburger img {
    width: 15px;
  }
}
@media(max-width:767px){
  .voting_sec .img_banner_cen, .about_sec .img_banner_cen
  {
    padding-bottom: 3rem !important;
  }
  .voting_sec .row
  {
    flex-direction: column-reverse;
  }
  .signup {
    border-radius: 0 0 15px 15px;
}
  .reffincome.mobilejc{
    justify-content: unset;
  }
  .tmcfeature .whitebox p {
    font-size: 14px;
  }
  .banner h1 {
    font-size: 30px;
  }

  .banner .banner_contn
  {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .d-none-767{
    display: none;
  }
  .d-block-767{
    display: block;
  }
  .search {
    display: none;
  }
  button.hamburger {
    width: 45px;
  }
}
@media(max-width:575px){
  .adminlogo
  {
    width: 40px !important;
  }
  .mobiletog
  {
    padding-right: 0px !important;
  }
  .adminheader
  {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .social_icon_fixed
  {
    display: none;
  }
  .img_banner_cen
  {
    text-align: center;
  }
  .center_con_home.mb-5
  {
    margin-bottom: 20px !important;
  }
  .center_con_home
  {
    font-size: 30px !important;
  }
  .card_green .card-body
  {
    padding-top: 25px !important;padding-bottom: 25px !important;
  }
  .form_contact
  {
    margin-top: 30px;
  }
  .link_col
  {
    align-items: flex-start !important;
  }
  .footer_menu a
  {
    padding-left: 0px !important;
  }
  .footer_news_row .btn 
  {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .banner_lit_font,.banner_thick_font,.text_inner_cob
  {
    font-size: 25px !important;
    margin-bottom: 10px !important;
  }


  .supportedcurrecy ul li {
    margin-right: 15px;
  }
  .banner h1 {
    padding-left: 20px;
}
 
  .supportedcurrecy ul li img {
    width: 45px;
    height: 45px;
  }
  .supportedcurrecy ul li p {
    font-size: 12px;
}
  #sidebar ul li {
    padding: 10px 10px 10px 15px;
  }
  .whitebox {
    padding: 19px 13px;
}

  .compress .drop.dropend > button,.compress li:hover .drop.dropend > button {
    color: #fff !important;
    width: 50px;
  }
  #sidebar.compress{
    max-width: 100%;
    width: 100%;
    z-index: 9;
  }
  #sidebar.expand {
    /* width: 50px; */
    width: 0;
    z-index: 9;
  }
  #sidebar.expand li img{
    display: none;
  }
  #sidebar.expand  li span {
    display: none;
  }
  #sidebar.compress  li span {
    display: inline-block !important;
  }
  #cont {
    /* width: calc(100% - 50px); */
    width: 100%;
  }
  .reffincome .aqua {
    font-size: 16px;
  }
  .reffincome img {
    height: 25px;
  }
  ul.coinbal li {
    display: inline-block;
  }
  ul.coinbal  {
    white-space: nowrap;
  }
  .h84-575{
    height: 84px !important;
  }
  ul.coinbal li {
    margin-right: 15px;
  }
  ul.coinbal li p{
    margin-bottom: 0;
  }
}
@media(max-width:420px){
  .f-10-420{
    font-size: 10px;
  }
  .supportedcurrecy ul {
    max-width: 360px;
    overflow-x: auto;
    justify-content: unset;
  }
}
@media(min-width:767px){
  .w-sm-50{
    width: 50%;
  }
}

.logo {
  width: 150px;
  height: 65px;
  
}
.drop.show.dropend >div, .themedrop > div {
  background: #fff;
  z-index: 99999;
  border-radius: 10px;
}
.drop.show.dropend div a:hover, .drop.show.dropend div .dropdown-item:hover, .themedrop > div a:hover {
  background: #c7cacd63;
}
#homeheader a.link_mr
{
  margin-right: 20px;
}
.themedrop.btn-bord a
{
color:#000 !important;
font-weight: 500 !important;
}
.banner_lit_font
{
  color:#fdffff;
  font-size: 30px;
  font-weight: 400;
}
.banner_thick_font
{
  color:#fdffff;
  font-size: 30px;
  font-weight: 700;
}
.banner_contn
{
  display: flex;
  min-height: calc(100vh - 80px);
  align-items: center;
  padding-bottom: 100px;

}
.card_upper
{
  border: none;
  border-radius:45px !important;
  margin-top: -150px;
  box-shadow: 0 0 16px 5px rgb(0 0 0 / 20%);
}
.card_upper p
{
  color:#000;
  font-size: 25px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 0px;
}
.center_con_home
{
  color:#000;
  font-size: 60px;
  font-weight: 700;
}
.home_left_desc
{
  font-size: 16px;
  color:#000;
  line-height: 2;
}
.team_name
{
  color:#060606;
  font-size: 18px;
  font-weight: 500;
}
.team_desc
{
  color:#5d5757;
  font-size: 15px;
  font-weight: 500;
}
.team_sec .owl-next,.team_sec .owl-prev
{
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #11B3BF !important;
  color:#000 !important;
  border:1px solid #11B3BF !important;
}
.team_sec .owl-next:hover,.team_sec .owl-prev:hover
{
  background: #000 !important;
  color:#11B3BF !important;
  border:1px solid #000 !important;
}
.team_sec .owl-nav
{
  margin-top: 40px !important; 
}
.text_inner_cob
{
  color:#000;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 0px;
}
.card_green .card-body
{
  background-color: #11B3BF;
  border-radius: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 30px;
  padding-right: 30px;
  background-image: url("./Assets/Images/footer_bg.png");
  
    background-size: 250px;
    background-position: right 0px bottom 0px;
    background-repeat: no-repeat;
  
}
.card_green
{
  border: none !important;
  border-radius: 20px;
  height: 100%;

}
.contact_icon_sec 
{
  color:#000;
  font-size: 16px;
}
.contact_icon_sec .fa
{
  font-size: 20px !important;
}
.form_contact label
{
  color:#193c4c;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}
.form_contact .input-group-text
{
border-right: none !important;
border-top-right-radius: 0px;
border-bottom-right-radius: 0px;
border-top-left-radius: 10px;
border-bottom-left-radius: 10px;
background-color: transparent;
height: 45px;
border-color: #e0e0e0 !important;
outline: -webkit-focus-ring-color auto 0px !important;

}
.form_contact  .form-control
{
  border-left: none !important;
  border-top-right-radius: 10px;
border-bottom-right-radius: 10px;
box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%) !important;
border-color: #e0e0e0 !important;
outline: -webkit-focus-ring-color auto 0px !important;
background-color: transparent;


}
.form_contact .input-group
{
  border-radius: 10px;
border-color: #e0e0e0 !important;
outline: -webkit-focus-ring-color auto 0px !important;

}
.form_contact .input-group:hover .input-group-text,
.form_contact .input-group:hover .form-control,
.form_contact .form-control:hover,.form_contact .form-control:hover
{
outline: -webkit-focus-ring-color auto 0px !important;
border-color: #1c3b49 !important;
}
.form_contact .form-control:focus
{
border-color: #e0e0e0 !important;
outline: -webkit-focus-ring-color auto 0px !important;

}
.form_contact .form-control::placeholder
{
  color:#000 !important;
}


/* Customize the label (the container) */
.container_radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container_radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container_radio:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container_radio input:checked ~ .checkmark {
  background-color: #193c4c;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container_radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container_radio .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.form_contact .form-group textarea.form-control
{
  border: 1px solid #ced4da !important;
  border-radius: 10px !important;
  resize: none !important;
}

.text-right
{
  text-align: right !important;
}
.btn
{
  box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%) !important;
}
.footer_text
{
  color:#fefefe;
  font-size: 14px;
  text-align: justify;
  line-height: 2;
}
.footer_sec
{
  background-color: #193d4d;
}
#footer .form_contact .form-control,
#footer .form_contact .input-group-text
{
background-color: #fff !important;
}
.footer_news_row .btn
{
  max-height: 45px !important;
  padding: 10px 25px !important;
}
.list_inline_social
{
  display: flex;
  align-items: center;
}
.li_inline_social
{
  margin-right: 10px;
}
.li_inline_social:last-child
{
  margin-right: 0px !important;
}
.li_inline_social a
{
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  background-color: #fff;
  color:#000;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
}
.li_inline_social a:hover
{
  background-color: #11B3BF;
  color:#000; 
}
.footer_title
{
  color:#fefefe;
  font-size: 18px;
  font-weight: 700;
}
.footer_menu a
{
color:#fefefe !important;
}
.footer_menu a:hover
{
  color:#11B3BF !important;
}
#footer img
{
  max-width: 200px;
}
.link_col
{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hr_white
{
  background-color: #fefefe !important;
}
@media  only screen and (max-width:575px)
{
  .card_green .card-body
  {
    background-size: 95px !important;
  }
  .list_inline_social
  {
    padding-bottom: 25px !important;
  }
  .owl-carousel .owl-item img.team_img
  {
    max-width: 200px !important;
  }
  .team_sec .owl-nav
  {
    margin-top: 20px !important;
  }
 .checkbox_row
 {
  flex-direction: column;
 }
  .checkbox_row .me-3
{
  margin-right: 5px !important;
}
.card_upper
{
  margin-top: 0px !important;
}
.footer_news_row .btn 
{
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.loginicon {
  height: 90px !important;
}
}


.container,.containerlogin
{
  max-width: 90% !important;
}
.containerlogin{max-width: 1440px;margin: 0 auto;}
@media only screen and (min-width:576px) and (max-width:767px)
{
  .banner .banner_contn
  {
    margin-top: -100px;
  }
  .card_green .card-body
  {
    background-size: 200px !important;
  }
  .list_inline_social
  {
    padding-bottom: 25px !important;
  }
  .owl-carousel .owl-item img.team_img
  {
    max-width: 200px !important;
  }
  .team_sec .owl-nav
  {
    margin-top: 20px !important;
  }
  .social_icon_fixed
  {
    left: 8px !important;
  }
  .card_upper
  {
    margin-top: 0px!important;
  }
  .img_banner_cen
  {
    text-align: center;
  }
  .form_contact
  {
    margin-top: 30px;
  }
  .link_col
  {
    align-items: flex-start !important;
  }
  .footer_menu a
  {
    padding-left: 0px;
  }
}

@media only screen and (min-width:768px) and (max-width:991px)
{
  .col_right
  {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .banner_contn 
  {
    margin-top: -100px;
    padding-bottom: 145px !important;
  }
  .social_icon_fixed
  {
    left:15px !important;
  }
  .container_radio
  {
    padding-left: 30px!important;
  }
  .checkbox_row .me-3
  {
    margin-right: 5px !important;
  }
  .link_col
  {
    align-items: flex-start !important;
  }
  .footer_menu a
  {
    padding-left: 0px !important;
  }
}
#homeheader a:hover
{
  color: #11B3BF !important;
}
.footer_menu a
{
  padding-left: 0px !important;
}
#homeheader .dropdown a:hover
{
  color:#000 !important;
}
.social_icon_fixed a .fa
{
color:#fff !important;
font-size: 20px !important;
}
.social_icon_fixed a
{
  display: block !important;
  margin-bottom: 20px;
}
.social_icon_fixed
{
  position: sticky;
  top:300px;
  left:30px;
  width: 100px;
}

@media only screen and (min-width:992px) and (max-width:1199px)
{
  #homeheader a.link_mr
  {
    margin-right: 13px !important;
  }
  .col_right
  {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .card_green .card-body
  {
    background-size: 200px !important;
  }
  .social_icon_fixed
  {
    left:20px !important;
  }
  .banner_contn
  {
    margin-top: -50px;
    padding-bottom: 200px;
  }
}

@media only screen and (min-width:1200px)
{
  .col_right
  {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .owl-carousel .owl-item img.team_img
  {
    max-width: 200px !important;
  }
  .container
  {
    max-width: 95% !important;
  }
  .containerlogin
  {
    max-width:1440px !important;
    margin: 0 auto;
  }
  .banner_contn
  {
    margin-top: -70px;
    padding-bottom: 175px !important;
  }
}
.banner_contn .col_left
{
  display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
}
.col_left .btn
{
  max-width: 180px;
}
.card_upper img
{
  max-width: 100px;
}
.img_sec_card
{
  min-height: 100px;
}
.team_img
{
  max-width: 150px;
  border-radius: 50%;
}
.social_icon_fixed a:hover .fa
{
  color:#11B3BF !important;
}
.pulse,.team_img
{
  cursor: pointer;
}
.pulse:hover,.team_img:hover {
  transform: scale(1);
  animation: 2s infinite pulse;
}
@keyframes pulse {
  0%,
  100% {
      transform: scale(0.75);
  }
  70% {
      transform: scale(1);
  }
}

@media only screen and (min-width:992px)
{
  #homeheader .navbar-nav
  {
    align-items: center;
  }
}
#homeheader a.link_mr
{
  cursor: pointer;
}
.bg_grey
{
  background-color: #f2f2f2;
}
.fincae_res
{
  max-width: 80% !important;
}


/* ...........v.......... */
.modal-backdrop.show{opacity: .8;}
.walletmodal .shadowbox {
  text-align: center;
  max-width: 135px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  border: 1px solid #00c7be;
  border-radius: 11px;cursor: pointer;transition: all 0.5s ease;
}

.resptable input[type="checkbox"] {
  position: relative;
  width: 30px;
  height: 15px;
  -webkit-appearance: none;
  appearance: none;
  background: #daf2fa;
  outline: none;
  border-radius: 2rem;
  cursor: pointer;
  /* box-shadow: inset 0 0 5px rgb(0 0 0 / 50%); */
}

.resptable input[type="checkbox"]::before {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #abafb3;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s;
}
.fa-eye-slash{color: #9A9A9A;cursor: pointer;}
.shoeeior{display: flex;align-items: center;justify-content: center;overflow: hidden;min-height: 130px;max-height: 130px;}
.shoeeior img{max-width: 100%;object-fit: cover !important;min-height: 130px !important;}
.shoeeior1{display: flex;align-items: center;justify-content: center;overflow: hidden;min-height: 60px;max-height: 60px;border-radius: 10px;}
.shoeeior1 img{max-width: 100%;object-fit: cover !important;}
.fa-eye{cursor: pointer;}
.resptable input[type="checkbox"]:checked::before {
  transform: translateX(100%);
  background: #00b3be;
}

.resptable input[type="checkbox"]:checked {
  background: #daf2fa;
}

.native_token{align-items: center;}
.native_token h2{font-size: 14px;margin-bottom: 0;font-weight: 400;
  text-align: right;}
  table {
    border-collapse: separate !important;
    border-spacing: 0 1em;
}
#pagination-next-page,#pagination-last-page,#pagination-previous-page,#pagination-first-page{margin-left: 10px;}
#pagination-next-page,#pagination-last-page,#pagination-previous-page,#pagination-first-page{background-color: #fff;border-radius: 50px;display: flex;align-items: center;width: 35px;height: 35px;}
#pagination-next-page:hover{background-color: #11B3BF;border-radius: 50px;}
#pagination-next-page:hover svg{fill: #fff !important;}
.rdt_Pagination{    display: flex !important;
  align-items: center !important;
  justify-content: center !important;background-color: #daf2fa !important;}
  .rdt_TableRow{border-bottom: 5px solid #daf2fa !important;transition: all 0.5s ease;background-color: #fff !important;}
  .rdt_TableRow:hover{border-bottom: 5px solid #daf2fa !important;    box-shadow: 1px -2px 20px 1px #81818163;
   
        }
        .rdt_TableHead{    height: 50px !important;
          background: #daf2fa;}
        .nounder.active .active{display: none;}
        .nounder.active .inactive{display: block;}
.native_token button{background-color: #11B3BF;border: 1px solid #11B3BF;color: #fff;padding: 5px 25px;border-radius: 8px;font-size: 12px;margin-left: 20px;transition: all 0.5s ease;}
.native_token button:hover{background-color:transparent;color: #000;}
.native_token input{--webkit-appearance:none;appearance: none;border: 1px solid #ccc;border-radius: 5px;font-size: 14px;height: 31px;    width: 100%;
  padding-left: 8px;
}
  .native_token input:focus-within{outline: none;}
.walletmodal .shadowbox p{color: #fff;}
.walletmodal .shadowbox:hover{background-color: #00c7be;}
.modal-dialog{
  max-width: 450px !important;
  margin: auto;
}
 .modal-content{
  border-radius: 15px;
  background-color: #000 !important;
  position: relative;
}
.showinputt input{height: 35px !important;}
.modal.fade .modal-dialog{transform: translate(0,29px) !important;}
.shoelisd,.shoelisd1,.shoelisd2,.shoelisd3,.shoelisd4{position: relative;}
.add_tokens{    background-color: #101010;
  margin: 10px;
  margin-bottom: 18px;border-radius: 15px;}
  .shoelisd .showselect{position: absolute;top: 8px;left: 8px;bottom: 0;right: 0;width:90%;opacity: 0;height: 90%;cursor: pointer;}
  .shoelisd1 .showselect{position: absolute;top: 0px;left: 11px;bottom: 0;right: 0;width:76%;opacity: 0;height: 90%;cursor: pointer;}
  .shoelisd2 .showselect{position: absolute;top: 0px;left: 11px;bottom: 0;right: 0;width:76%;opacity: 0;height: 100%;cursor: pointer;}
  .shoelisd3 .showselect{position: absolute;top: 0px;left: 11px;bottom: 0;right: 0;width:76%;opacity: 0;height: 100%;cursor: pointer;}
  .shoelisd4 .showselect{position: absolute;top: 0px;left: 11px;bottom: 0;right: 0;width:76%;opacity: 0;height: 100%;cursor: pointer;}
  .tokenh2{font-size: 14px;color:#00c7be ;}
  .add_tokens img{background-color: #fff;padding: 15px;border-radius: 10px;max-width: 100%;height: auto;object-fit: cover;width:100%;}
.wallmodal .themeclr{color: #00c7be;}
button.close-btn {
  padding: 0 !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 100%;
  position: absolute;
  top: -30px;
  background-color:#00b3be !important;
  right: -30px;
}

.showsidebar .headerbtn {margin: 0 !important;}
.showsidebar .header .headerbtn {margin: 1.5rem!important;}
.rdt_TableHeadRow{height: 10px;}
.modal-content hr{border-bottom: 2px solid #00b3be;opacity: 1;}
.fa-times{color: #000 !important;}
.headerbtn{
  padding: 11px 25px !important;
  box-shadow:0 0 24px -6px rgb(9 8 8 / 44%) !important
}

.btn.btn-bord.nav-link:hover {
  color: #000 !important;
  background-color: #fff;
}
.btn.btn-bord.nav-link:focus {
  color: #000 !important;
}
.calendar .dropdown-menu.show {
  left: -42px !important;
}
img.app1 {
  height: 155px;
  width: 150px;
}
@media(max-width:425px){
  .calendar .dropdown-menu.show {
  transform: translate(-46px, 37px) !important;
  }


  .shadowbox p {
    font-size: 12px;
    font-weight: 600;
}

.btn.btn-bord.nav-link {
  padding: 3px 0;
  font-size: 12px;
}
button.btn.btn-theme {
  border-radius: 9px;
  padding: 8px 20px;
  font-size: 12px;
}
.voting .dropdown button {
  font-size: 13px !important;
}
}
.mobconnect{display: none;}
@media(max-width:575px){
  .headlogo{
    height: 40px !important;
  }
  .modal-dialog {
    max-width: 300px !important;
  }
  .showsidebar .header .headerbtn{margin: 0 !important;}
  .admin-prof img{margin-left: 0 !important;}
  .admin-prof button{font-size: 14px !important;}
  .webconnect{display: none;}
  .mobconnect{display: block;}
  .admin-prof img {
    height: 20px !important;
    width: 20px !important;}
  .calendar .react-datepicker__month-container {
    width: 100%;
  }
  .calendar .dropdown-menu.show {
    width: 265px !important;
    padding: 10px;
  }
  .headerbtn {
    padding: 11px 12px !important;
}

}

.hash{
  color: #000;
  
}
.table_view_shoes .btn-theme.aquabtn
{
min-width: 65px !important;
max-width: 65px !important;
display: flex;
align-items: center;
justify-content: center;
}