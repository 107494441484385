@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap');
:root{
  --themeclrs: #1A3D4D;
  --txtgray: #7a7b7d;
  --bordclr: #b0b0b0;
  --textgray: #484848;
  --adminblue:#3655b6;
  --aqua:#11B3BF;
  --graybg: #DCF2FB;
}
.shadowbox{
  box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px !important;
}
*{
    padding: 0;
    margin: 0;
  }
  body{
    /* font-family: 'Roboto', sans-serif; */
    background-color: #000;
    font-family: 'Poppins', sans-serif;
  }
  li{
    list-style-type: none;
  }
  .liststyledots li{
    list-style-type: disc;
  }
  .textgray{
    color: var(--textgray)
  }
  .fw-500{
    font-weight: 500;
  }
  .rdt_Pagination span {
    display: none;
}
.rdt_Pagination{
  border-radius: 0 0 30px 30px;
}
#pagination-next-page svg, #pagination-last-page svg,
#pagination-previous-page svg, #pagination-first-page svg {
  fill: var(--aqua) !important;
}
#pagination-next-page[disabled] svg, #pagination-last-page[disabled] svg,
#pagination-previous-page[disabled] svg, #pagination-first-page[disabled] svg {
  fill:lightgray !important;
}
  .themebg {
    background-image: linear-gradient(45deg, #082e53, #f6be09);
  }
  .mh-100vh{
    min-height: 100vh;
  }
  .mh-100vh-header{min-height: calc(100vh - 138px);}
  .mh-100vh-filterheader-footer{    min-height: calc(100vh - 252px);}
.themeclr{
  color: var(--themeclrs);
}
.br-30{
  border-radius: 30px;
}
.rdt_TableHead div {
  background: #daf2fa !important;
  color: #464A53;
}

.rdt_TableRow, .rdt_TableHead {
  height: 60px;
}
.rdt_TableRow div, .rdt_TableHead div{
  justify-content: center;
}
  .themehr {
      background-color: #858585;
  }
.nounder{
  text-decoration: none;
}
.nolink{
  cursor: auto !important;
}
.upper{
  text-transform: uppercase;
}
/* common */
.themebord{
  border-color:#dadada ;
}
.graytxt {
  color: #717171;
}
.flex-1 > * {
  flex: 1;
}
.jc-end{
  justify-content: end;
}
.jc-center{
  justify-content: center;
}
.jc-between{
  justify-content: space-between;
}
.align-center{
  align-items: center;
}
.btn.disable {
  cursor: not-allowed !important;
}
a.navbar-brand img,.logo img {

  max-width: 145px !important;

}
.adminlogin .shadowbox {
  box-shadow: rgb(52 52 52) 0px 6px 16px !important;
  background-image: url("../Images/adlobg.png");
  background-size: cover;
}
.shadowbox  h3 {color: #fff;font-size: 14px;margin: 0;}
.shadowbox  h4 {color: #fff;font-size: 28px;    margin-bottom: 6px !important;}
.br-10 {
  border-radius: 10px;
}
.form-switch .form-check-input {
  height: 20px;
}
p{
  margin-bottom: 0;
}
.bord {
  border: 1px solid #ebebeb;
  margin-bottom: 17px;
}
.mb-85{
  margin-bottom: 85px;
}
.mt-78{
  margin-top: 78px;
}
.f-50{
  font-size: 50px;
}
.form-check {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-between;
}
.form-check-input:checked {
  background-color: var(--themeclrs);
  border-color: var(--themeclrs);
}
.countbtns button:hover {
  border-color: gray;
}
button.nofocus:focus{
  box-shadow: none !important;
}
.mb-90{
  margin-bottom: 90px;
}
.height-100{
  height: 100%;
}
.min-height-100vh{
  min-height: 100vh;
}
.w-full {
  width: 95%;
}
input::placeholder{
  font-size: 14px;
}
.fw-500{
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}

.f-14{
  font-size: 14px;
}
.f-12{
  font-size: 12px;
}
.btn-theme{
  background: var(--themeclrs) !important;
  color:var(--second) !important;
}
.btn-theme.aquabtn{
  font-size: 15px;
  padding: 12px 14px;
  min-width: 150px;
  background-color: var(--aqua) !important;
  color: #fff !important;
  border-color: var(--aqua) !important;
  font-weight: 600;
  border-radius: 10px;
}
.btn-theme.aquabtn:hover{
  background-color:#fff !important;
  border-color: var(--themeclrs) !important;
  color: #000 !important;
}
.modal-content{
  padding: 20px;
  border-radius: 30px;
}
.resptable{
  border-radius: 30px 30px 0 0;
}
button.close-btn {
  padding: 0  !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 100%;
}
.themeclr{
  color: var(--themeclrs)
}
.txtgray{
  color:var(--txtgray)
}
.bordclr{
  color:var(--bordclr)
}
.btn-theme {
  background-color: var(--themeclrs);
  color: var(--aqua) !important;
  padding: 6px 28px;
  font-size: 20px;
  font-weight: 400;
  border-radius: 18px;
  border-color: #082e54;
}
.btn-theme:hover{
    background-color: var(--aqua) !important;
    border-color: var(--aqua) !important;
    color: var(--themeclrs) !important;
}
.btnbord {
  border-radius: 8px;
}
select:focus, input:focus, textarea:focus{
  box-shadow: none !important;
}





/* admin css */

#dashboard {
  display: flex;
  gap: 20px;
}
#sidebar {
  width: 250px;
  position: absolute;
  background-color: #000;
  transition: 0.5s;
  height: 100vh;
  z-index: 999;
  position: fixed;
  margin-top: 85px;

}
.showsidebar .adminsmalllogo {
  display: none;
}
.showsidebar .adminlogo {
  display: unset;
}
/* .adminlogo {
  display: none;
} */
.adminsmalllogo{
  height: 70px;
}
.adminlogo{max-width: 100%;height: auto;width: 50px;}
.hidesidebar .sidelist {
  display: none;
}
.hidesidebar #sidebar {
  width: 0;
  transition: 0.5s;
}
.ham {
  height: 14px;
}
.sidebar{
  position: relative;
}
.sidebar .sidelist {
  height: 100vh;
  padding-left: 0;
  margin-top: 110px;
}
.sidebar ul{
  overflow-y: auto;
}
.sidebar .menus button,
.sidebar .menus a{
    /* padding-left: 15px; */
    display: inline-block;
    text-align: left;
    position: relative;
    z-index: 1;
    width: 100%;
    color: #fff;
    background: transparent;
}

.menus.active .inactive{
  display: inline-block;
}
.menus.active img.active{
  display: none;
}

.menus .inactive{
  display: none;
}
.menus img.active{
  display: inline-block;
}

.sidebar .menus button * {
  pointer-events: none;
}
.sidebar .menus button:after {
  content: "";
  height: 100%;
  width: 0%;
  background: transparent;
  position: absolute;
  left: 0;
  top:0;
  z-index: -1;
  /* transition: 0.2s */
}

.sidebar .menus button:hover:after{
  display: inline-block;
  width: 100%;
  background: var(--themeclrs);
}
/* .sidebar .menus a:hover:after{
  display: inline-block;
  width: 100%;
  background: var(--themeclrs);
  color: #fff;
} */


.sidebar .menus a.active {
  color:#fff;
}

.sidebar .menus button,.sidebar .menus a {
  padding-top: 5px;
  padding-bottom: 5px;
  /* padding-left: 12px; */
  border: 0;
  color: #aeacac;
}
.menus button img {
  width: 16px;
}


.sidebar .menus button img{
  margin-right: 10px;
}
.sidebar .menus button span,.sidebar .menus a {
  color: #00b3be;
  font-size: 14px;
  font-weight: 500;
  height: 45px;
}


button.togglebtn {
  transition: 0.5s;
  /* position: absolute; */
  top: 65px;
  right: -9px;
  background: white;
  border: none;
  box-shadow: 0 0 5px silver;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.loginicon {
  height:140px;
}
.adminheader{
  padding-left: 20px;
  transition: 0.5s;
  padding-right: 18px;
}
.menus a.active {
  background: #11B3BF;
  margin-left: 0px;
  border-radius: 30px 0 0 30px;
  position: relative;
  padding-left: 30px !important;
  height: 45px;
}
.menus
{
  position: relative;

  z-index: 99;
}
.menus a
{
  height: 58px;
  position: relative;
  display: flex !important;
    align-items: center;
    z-index: 1;
}

.menus img {
  margin-right: 20px;
  width: 25px;
}

/* .rdt_Table img {
  max-width: 35px;
} */

.rdt_Table .img_table_div
{
max-width: 35px;
max-height: 35px;
min-height: 35px;
min-width: 35px;
border:1px solid #cbcbcb;
display: flex;
align-items: center;
justify-content: center;
overflow: hidden;
}
.rdt_Table .img_table_div img
{
  max-width: 100%;
}
 .cont {
  margin-left: 250px;
  transition: 0.5s;
  padding-right: 18px;
  border-radius: 0;
  background-color: var(--graybg);
  min-height: calc(100vh - 72px);
  margin-top: 72px;
}
.menus {
  /* padding-top: 11px; */
  /* padding-bottom: 12px; */
  padding-left: 15px;
}
.menus a
{
  padding-left: 30px;
}


.headandcont{
  width: 100vw;
}
.hidesidebar .adminheader,.hidesidebar .cont{
  /* width: 100vw; */
  margin-left: 0px;
  padding-left: 20px;
  transition: 0.5s;
  border-radius: 0;
} 
.modal input {
  border-radius: 11px;
  border-color: #d4d4d4;
}
.input-group span {
  background: transparent;
  border: 0;
  color: #4FC4EA;
}
.input-group {
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #4FC4EA;
}
.shadowbox  .aquabtn{color: #000 !important;}
.shadowbox  .aquabtn:hover{color: #000 !important;background-color: #fff !important;}
.input-group input{
  border: 0;
  border-radius: 30px;
  background: none !important;
  background-color:transparent !important;
  color: #fff !important;
}
.input-group input::placeholder{
 color: #c7c7c7
}
.mb-4.input-group .input-group-text {
  border: 0;
}
.modal input::placeholder {
 color: #c7c7c7 !important;
}
.hidesidebar .menus {
  opacity: 0;
}
.menuopen {
  width: 26px;
}
.mobiletog {display: none;}
.sidebar .menus a{
  white-space: nowrap;
}
.adminheader {
  background: #000;
  height: 72px;
  display: flex;
  justify-content: end;
  align-items: center;
  position: fixed;
    width: 100%;
    z-index: 99;
}
#dashboard .rdt_TableHeadRow {
  color: var(--adminblue);
  font-weight: 600;
  border-bottom: none !important;
}
.adminblue{
  color:var(--adminblue)
}
.circleprog {
  height: 100px;
  width: 100px;
  background: #4565b0;
  background-image: conic-gradient( #4565b0 344deg , #dedcdc 0deg);
  border-radius: 100%;
  position: relative;
}
.upimages img {
  max-width: 80px;
  object-fit: cover;
  margin-right: 10px;
}
.circleprog .overlay {
  position: absolute;
  height: 76px;
  width: 76px;
  background: #fff;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}
span.title.timer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 99;
}
.dash-state {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));;
  column-gap:20px;
  row-gap: 20px;
}
.gradbox {
  padding: 21px;
  background-image: linear-gradient(45deg, #fff 27%, #ede9e9);
}
.tableview .responsive{
  max-width: 100%;
  overflow-x: auto;
}
.as-react-table .row{
  margin-left: 0;
  margin-right: 0;
}
.tableview .table-striped>tbody>tr:nth-of-type(odd)>*{
  --bs-table-accent-bg: #fff;
}
.tableview .table-bordered>:not(caption)>*>*{
  border-width: 0;
}
.tableview .table>:not(:first-child){
  border-width: 0;
}
.tableview th {
  font-size: 15px;
}
.tableview  .asrt-table-head{
  margin-bottom: 40px;
}
.tableview td {
  font-size: 14px;
  color: gray !important;
}
.tableview .col-md-6.float-right.text-right {
  text-align: right;
}
.tableview .table_tools .btn,
.tableview td .btn {
  background: #3655b6;
}
.tableview .page-item a {
  color: #3655b6 !important;
}
.tableview .disabled.page-item a {
  color: #d2d2d2 !important;
}
.tableview span.input-group-text {
  font-size: 14px;
}
.tableview .table-foot {
  font-size: 14px;
}
.bluebg {
  background: #074687;
}
.adminlogin{
  background-image: url("../Images/adminbg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}



.date-pic .react-datepicker {
  width: 100%;
}
.date-pic .react-datepicker__header {
  width: 100%;
  background: transparent !important;
  border: 0 !important;
}
.date-pic .react-datepicker__month {
  width: 98%;
}
.date-pic .react-datepicker__month-container{
  float:unset !important
}
.date-pic .react-datepicker__week,.date-pic .react-datepicker__day-names {
  display: flex;
  justify-content: space-between;
}
.date-pic .react-datepicker__week > div {
  /* flex: 1 1; */
  width: 40px !important;
  /* background: red; */
  /* border-radius: 50%; */
  aspect-ratio: 1/1;
  line-height: 3;
}
.date-pic .react-datepicker{
  border: 0 !important;
}
.admin-forms .form-floating>.form-control:focus~label,.admin-forms .form-floating>.form-control:not(:placeholder-shown)~label,
.admin-forms .form-floating>.form-select~label {
 opacity: 1 !important;
  transform: scale(.85) translateY(-1.7rem) translateX(.15rem) !important;
  background: #fff;
  color: gray;
}
.outlinebtn {
  padding: 10px 12px;
  font-size: 12px;
  font-weight: 600;
  min-width: 100px;
  border-radius: 8px;
}
.admin-forms label {
  color: gray;
}
.mobileadminlogo {
  height: 32px;
  display: none;
}
/* .mobiletog{
  display: none;
} */
.admin-prof img{
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.admin-prof .show>.btn-success.dropdown-toggle,
.admin-prof .btn-success:hover{
  background-color: transparent;
}
.admin-prof button{
  background-color: transparent;
  border: 0;
}
.admin-prof button:after{
  display: none;
}
@media(max-width:767px){
  /* .adminheader, .cont{
    padding-left: 20px;
  } */
  #sidebar {
    margin-top: 10px;
}
  
  .hidesidebar .menus{
    opacity: 1;
  }
  .hidesidebar .sidelist{
    display: block;
  }
  .adminheader, .cont {
    margin-left: 0;
    border-radius: 0;
}
  .mobileadminlogo {
    display: block;
}
.mobiletog{
  display: block;
}
  #sidebar {
    width: 100%;
    z-index: 9;
  }
  button.togglebtn {
    right: 6px;
    top: 63px;
  }
  .hidesidebar #sidebar{
    width: 250px;
  }
  .hidesidebar .sidebar ul span {
    display: inline-block;
}
  .showsidebar #sidebar {
    left: -200px;
  }
  .showsidebar .adminsmalllogo{
    display: none;
  }
  .hidesidebar .adminlogo{
    display: inline-block;
  }
  .adminsmalllogo{
display: none;
  }
  .showsidebar  #sidebar{
    width: 0;
  }
  
.hidesidebar .adminheader, .hidesidebar .cont {
    padding-left: 20px;
}
  
}
.h-55 select, .h-55 input, .spaninput input, .spaninput select{
  height: 55px;
}

