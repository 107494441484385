:root{
    --themeclr: #15c6be;
    --badgesclr:#c8fffa;
    --greyclr:#b5b5b5;
}
.themeclr{
    color: var(--themeclr)
}
.f-14{
    font-size: 14px;
}
.fw-700{
    font-weight: 700;
}
.jc-between{
    justify-content: space-between;
}
.upper{
    text-transform: uppercase;
}
.nounder, .nounder button{
    text-decoration: none;
  }
.noshadow input:focus,
.noshadow select:focus,.signin input:focus,.signup input:focus{
  box-shadow: none;
}
.shadowbox {
    box-shadow: 0 0 5px #d5d5d5;
    padding: 15px;
    border-radius: 5px;
}
.breakword{
    word-break: break-all;
}
.progress {
    height: 8px;
}
.progress-bar {
    background: var(--themeclr);
}
.voting .badge, .badge.themebadge{
    background-image: linear-gradient(90deg, #55b0a7 34%, #a3b7b6 75%);
    color: #000;
    border-radius: 3px 0 0 3px;
    padding: 0;
}
.voting .badge img, .badge.themebadge img {
    background-color: var(--themeclr);
    border-radius: 3px 0 0 3px;
    padding: 8px;
    height: 33px;
}
.voting .badge  span ,.badge.themebadge span{
    display: inline-block;
    padding: 10px;
}
.bordbtn {
    background: transparent;
    color: #000;
    border-color: var(--themeclr);
    border-radius: 0;
}
.calendar .dropdown-menu.show {
    width: 505px ;
    padding: 10px;
}
.voting .dropdown button{
    box-shadow:  none !important;
    font-size: 14px;
}
.voting .dropdown button:hover ,.voting .dropdown button:focus,
.voting .dropdown button:active{
    background: transparent;
    color: #000;
    box-shadow: none;
}
.headlogo {
    height: 60px;
}
.header {
    padding: 34px 0;
}
.calendar button:after {
    display: none;
}
.react-datepicker {
    border: none !important;
}
.react-datepicker__header {
    background: transparent !important;
    border: none !important;
}
.react-datepicker__day--keyboard-selected {
    background: var(--themeclr) !important;
}
.react-datepicker__day--keyboard-selected {
    background: var(--themeclr) !important;
}
.btn.btn-graybtn {
    background: #dfdfdf;
    color: gray;
    font-size: 14px;
    font-weight: 600;
    height: 50px;
}
.notes {
    background: #c1fcf8;
    border-left: 5px solid #15c6be;
    border-radius: 6px;
    font-size: 14px;
    padding: 15px;
}
.offcanvas-header button {
    background-color: var(--themeclr);
    border-radius: 50%;
    height: 10px;
    width: 10px;
    background-size: 10px;
}
.tokentrunk {
    width: 136px;
    text-overflow: ellipsis;
    overflow-x: hidden;
}
.themeinputgroup .input-group-text {
    background: transparent;
    border-left: 0;
}
.themeinputgroup input{
    border-right:0 
}
.offcanvas-body .nav.nav-tabs {
    border-bottom: 1px solid #dee2e6;
}

.offcanvas-body .nav-item button{
    border: 0;
    border-bottom: 1px solid #dee2e6 !important;
}
.offcanvas-body .nav-link{
    color: gray;
}
.offcanvas-body .nav-link.active {
    border-bottom: 1px solid var(--themeclr) !important;
    color:#000
}
button.btn.btn-theme.smallbtn {
    border-radius: 5px;
    padding: 7px 23px;
}
.finance .rdt_TableRow .rdt_TableCell:last-child,
.finance .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:last-child {
    text-align: right;
    justify-content: end;
}
.finance .rdt_TableCell >div {
    white-space: break-spaces !important;
}
.close-btn {
    border: 0;
    background: var(--themeclr);
    color: #000;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    font-size: 14px !important;
    box-shadow: none;
    margin-left: auto;
    margin-right: 0;
    position: absolute;
    font-size: 10px;
    right: 10px;
    top: 10px;
}
.walletmodal img {
    width: 55px;
    height:auto;
}




/* ..............m......... */
.f-16{
    font-size: 16px;
}
.f-18{
    font-size: 18px;
}
.f-12{
    font-size: 12px;
}
.voting-detail .badge{
    background-image: linear-gradient(90deg, var(--themeclr) 34%, #a3b7b6 75%);
    color: #000;
}
.voting-detail .right_side .first.badge{
 background-color: var(--badgesclr) !important;
 background-image: none !important;
    color: #000;
}
.voting-detail .badge img {
    width: 22px;
    height: 22px;
}
.voting-detail .shadowbox {
    box-shadow: 0 0 3px #d5d5d5;
    padding: 15px;
    border-radius: 10px;
    
}
.voting-detail .left-side .btn_sec{
    display: flex;
   float: right;
    gap: 10px   ;
}
.voting-detail .left-side .btn_sec .button-red{
    border: transparent  solid 1px !important;
        background-color:#e9ecef;
        border: none;
        color: rgb(0, 0, 0);
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 14px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 10px;
        height: 50px;
      
}
.voting-detail .left-side .btn_sec .button-red:hover {
    background-color: transparent !important;
    border: var(--themeclr) solid 1px !important;
    color:var(--themeclr);
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 10px;
    height: 50px;
}
.voting-detail .left-side .btn_sec .button-theme{
   
    background-color:var(--themeclr);
    border: transparent  solid 1px;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 10px;
    height: 50px;
  
}
.voting-detail .left-side .shadowbox{
    border: 1px solid #dddddd;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 80px;
    padding-top: 25px;
}
.voting-detail .greyclr{
    color: var(--greyclr)
}
.voting-detail .right_side .shadowbox{
    border: solid 1px;
    border-radius: 15px !important;
    height: 100%;
}
.voting-detail .left-side .f-18{
    font-weight: 600;
}
.voting-detail .left-side .icon_desc{
    width: 15px;
    height: 15px;
}
.voting-detail .left-side .red-dot {
    height: 10px;
    width: 10px;
    background-color:#fe0000;
    border-radius: 50%;
    display: inline-block;
  }
  .voting-detail .left-side .green-dot {
    height: 10px;
    width: 10px;
    background-color:var(--themeclr);
    border-radius: 50%;
    display: inline-block;
  }
  .voting-detail .back_button .shadowbox1 {
    border:#dfdfdf 1px solid;
    width: 100px;
    height: 50px;
    padding: 14px;
    text-align: center;
    
  }
  .voting-detail .back_button a {
    color: black;
  }
  .voting-detail .back_button .shadowbox1:hover {
    border: #dfdfdf 1px solid;
    background-color: var(--themeclr);
    color: rgb(255, 255, 255);
}
.finance .rdt_TableRow:hover {
    border-color: #e0e0e0;
    outline-color: #e0e0e0;
}
.finance .amount_sec .bordbtn {
    border-color: #e0e0e0 !important;
}
.voting-detail .left-side .btn_sec .button-theme:hover {
    background-color: transparent !important;
    border: var(--themeclr) solid 1px;
    color:var(--themeclr);
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 10px;
    height: 50px;
}
@media(min-width:992px){
    .voting-detail .right_side .shadowbox{
        height: 130px;
    }
}
@media(max-width:767px){
    .voting-detail .shadowbox {
        padding: 8px;
    }
}
  @media only screen and (max-width: 575px) {
    .finance .shadowbox.cardright {
      
        text-align: center;
    }
    .voting-detail .left-side .shadowbox {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
    .voting-detail .left-side .btn_sec {
            display: block;
            text-align: center;
            float: unset !important;
           
            gap: unset !important;
            padding-bottom: 20px;
        
       
    }
    .voting-detail .left-side .shadowbox {
        padding-bottom: 0px !important  ;
    }
  }
  @media(max-width:575px){
    .voting-detail .back_button .shadowbox1 {
        width: 73px;
        height: 33px;
        padding: 6px;
        text-align: center;
    }
    .voting-detail .left-side .shadowbox {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .voting-detail .left-side .btn_sec .button-red {
        padding: 15px 24px;
        margin-right: 5px;
    }
  }
/* .voting-detail .left-side .progress.red_progessbar{
    background-color: #fe0000 !important;
} */
.voting-detail .left-side .border_dash{

   border-right: dashed 2px rgb(90, 90, 90);
    position: absolute;
  
    height: 50px;
    left: 50%;
    top: -22px;
   

}
.voting-detail .left-side .progress_bar{
    position: relative;
    
}

.finance .token_modal .button-label{
    border: transparent  solid 1px;
        background-color:#e9ecef;
        border: none;
        color: rgb(0, 0, 0);
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 14px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 10px;
        box-shadow: 0px 1px #888888;
        height: 50px;
      
}
.finance .token_modal .button-label:hover {
    background-color: transparent !important;
    border: var(--themeclr) solid 1px;
    color:var(--themeclr);
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0px 1px #888888;
    height: 50px;
}

.voting-detail .right_side .border_dash{

    border-right: dashed 2px rgb(90, 90, 90);
     position: absolute;
   
     height: 20px;
     left: 50%;
     top: -05px;
    
 
 }
 .voting-detail .right_side .progress_bar{
     position: relative;
     
 }
 .voting-detail .right_side i{
    font-size: 10px;
    color: #15c6be;
    background-color: #000;
    border-radius: 100px;
 }


 .dis-spacing{
    display: flex;
    justify-content: space-between;
 }

 .finance .shadowbox1 {
    box-shadow: 0 0 2px #d5d5d5;
    padding: 12px;
    border-radius: 10px;
}
.finance .back_button a {
    color: black;
  }
  .finance .back_button .shadowbox1:hover {
    
    background-color: var(--themeclr);
    color: #000;
}
.finance .first_row .cardleft .firstp{
    font-weight: 500;
}
.finance .first_row .cardleft .secp{
  color: var(--themeclr);
  font-size: 16px;
}
.finance .first_row .cardleft .threep{
   font-weight: 500;
  }
  .finance .first_row .cardleft .fourp{
    border-bottom: #15c6be 2px solid;
    width: 100px;
    text-align: inherit;
    margin: auto;
    margin-bottom: -15px;

   }
   .finance .first_row .cardleft a{
  color: black;

   }
   .finance .first_row .cardright .sec{
    color: #888888;
    font-size: 20px;
   }
   .finance .first_row .cardright .first{
    font-weight: 500;
    font-size: 20px;
   }
   .finance .first_row {
   padding-top: 25px;
   padding-bottom: 25px;
   }
.finance .value_token{
    display: flex;
    gap: 10px;
}
.finance .shadowbox.cardleft{
    background-image: url(../src/Assets/Images/finance_card2.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;

}
.finance .shadowbox.cardleft button.btn.btn-theme:hover {
    background: transparent;
    color: #ffffff !important;
}
.amount_sec .btn-check:focus+.btn-success,.amount_sec .btn-success:focus,
.amount_sec .dropdown-item.active,.amount_sec .dropdown-item:active,
.voting .btn-check:focus+.btn-success,.voting .btn-success:focus,
.voting .dropdown-item.active,.voting .dropdown-item:active{
    background-color: var(--themeclr);
}
.voting .dropdown button:hover {
    background: var(--themeclr);
    border-color: var(--themeclr);
}
.arrow {
    width: 25px;
}
.finance .shadowbox.cardright{
    background-image: url(../src/Assets/Images/finance_card3.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    background-color: #15c6be;
    padding: 40px;
    height: 310px;

}
.finance .value_token .shadowbox1 {
    box-shadow: 0 0 2px #d5d5d5;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid;
}
.finance .icon_desc{
    background-color: var(--themeclr);
    border-radius: 3px 0 0 3px;
    padding: 8px;
    height: 30px;
}
.finance .source_value p{
    font-size: 12px;
    padding-right: 10px;
  
}
.finance .source_value{
    background-image: linear-gradient(90deg, #55b0a7 34%, #a3b7b6 75%);
}
.finance .amount_sec {
    display: flex;
    gap: 10px;
}
.finance .amount_sec P {
   color: #fe0000;
}
.finance .amount_sec .bordbtn {
    padding: 0px 10px;
    display: flex;
    align-items: center;
}
.finance .amount_sec{
    margin-top: 10px;
}
.rdt_Table div[data-column-id="3"] div
{
    overflow: visible !important;
}

.btn-success:hover {
    color: #fff !important;
    background-color:transparent !important;
    border-color:var(--themeclr);
}
.btn-check:active+.btn-success, .btn-check:checked+.btn-success, .btn-success.active, .btn-success:active, .show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color:transparent !important;
    border-color: none !important;
}

